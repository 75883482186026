<template>
  <base-modal-filters v-model="modelValue" @reset-filters="reset">
    <template #default="{ attrs, on }">
      <form-builder ref="formBuilder" :schema="$options.schema" v-bind="attrs" v-on="on" />
    </template>
  </base-modal-filters>
</template>

<script>
// Components
import BaseModalFilters from '@/components/BaseModalFilters.vue';
import FormBuilder from '@/components/schema/FormBuilder.vue';

// Models
import { schema } from '@/schemas/requestsFilter.schema';

// Utils
import { createModelWrapper } from '@/utils/components';

export default {
  name: 'RequestsModalFilters',

  components: { FormBuilder, BaseModalFilters },

  inject: ['media'],

  props: {
    value: { type: Object, default: () => ({}) },
  },

  computed: {
    modelValue: createModelWrapper('value', 'input'),
  },

  methods: {
    reset() {
      this.$refs.formBuilder?.reset();
    },
  },

  schema,
};
</script>
